import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Library2 } from './library2'
import { Loading } from './loading'
import './home.css'
import $axios from '../../tools/axiosWrapper'
import LoginIcon from '@mui/icons-material/Login'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
export const Banner = ({ user }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <>
      <Stack
        sx={{
          width: '100vw',
          py: 8,
          px: '10vw',
          color: theme.palette.white[2],
          borderTop: '1px solid ' + theme.palette.white[2],
          mt: 4,
        }}
        spacing={8}
        alignItems={'center'}
      >
        <Typography variant={'h6'} sx={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 600, color: theme.palette.white[0] }}>
            fbarchive.org
          </span>{' '}
          was designed to facilitate collaborative curation and resaearch.
        </Typography>

        <Stack
          sx={{
            width: '100%',
            background: theme.palette.purple[1],
            borderRadius: '20px',
            p: 4,
            pb: 8,
          }}
          alignItems={'center'}
          spacing={4}
        >
          <Stack sx={{ width: '100%' }}>
            <Typography variant={'h5'} sx={{ fontWeight: 400 }}>
              Findings:
            </Typography>
          </Stack>

          <Stack direction={'row'} sx={{ width: '100vw', px: '20vw' }}>
            <Stack sx={{ width: '50%' }} spacing={2}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Typography variant={'h6'}>Videos</Typography>
              </Stack>
              <a
                href={
                  'https://drive.google.com/file/d/1J5uvLNnmf3Os_b-DLbfd-2v53J-5UTWd/view'
                }
                target='_blank'
                className={'link'}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <OpenInNewIcon />
                  <Typography variant={'body1'}>Algorithmic Failure</Typography>
                </Stack>
              </a>
              <a
                href={
                  'https://drive.google.com/file/d/1CEzMnO_iOFswmmaGyJM1wTDoePdKIk6G/view'
                }
                target='_blank'
                className={'link'}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <OpenInNewIcon />
                  <Typography variant={'body1'}>Translation Issues</Typography>
                </Stack>
              </a>
              <a
                href={
                  'https://drive.google.com/file/d/1PiMpK7e2ebZVMFQ9CvzB0c6_Gyi77r2x/view'
                }
                target='_blank'
                className={'link'}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <OpenInNewIcon />
                  <Typography variant={'body1'}>
                    Language Prioritization
                  </Typography>
                </Stack>
              </a>
            </Stack>
            <Stack sx={{ width: '50%' }} spacing={2}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Typography variant={'h6'}>Investigations</Typography>
              </Stack>
              {/* <a
              href={
                'https://drive.google.com/file/d/1J5uvLNnmf3Os_b-DLbfd-2v53J-5UTWd/view'
              }
              target='_blank'
              className={'link'}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <OpenInNewIcon />
                <Typography variant={'body1'}>Algorithmic Failure</Typography>
              </Stack>
            </a>
            <a
              href={
                'https://drive.google.com/file/d/1CEzMnO_iOFswmmaGyJM1wTDoePdKIk6G/view'
              }
              target='_blank'
              className={'link'}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <OpenInNewIcon />
                <Typography variant={'body1'}>Translation Issues</Typography>
              </Stack>
            </a>
            <a
              href={
                'https://drive.google.com/file/d/1PiMpK7e2ebZVMFQ9CvzB0c6_Gyi77r2x/view'
              }
              target='_blank'
              className={'link'}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <OpenInNewIcon />
                <Typography variant={'body1'}>
                  Language Prioritization
                </Typography>
              </Stack>
            </a> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
