import React, { useEffect, useState, useContext, useRef } from 'react'

import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { SideItem } from './sideimg'

export const Side = ({ imgs, current, setCurrent }) => {
  const theme = useTheme()
  const ref = useRef([])

  useEffect(() => {
    ref.current = ref.current.slice(0, imgs.length)
  }, [imgs])

  useEffect(() => {
    if (current) {
      _.find(ref.current, {
        id: current?.imageid?.replace('_w32', ''),
      }).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [ref.current])

  return (
    <>
      <motion.div className='page-doc-list'>
        {imgs.map((d, i) => (
          <SideItem
            d={d}
            i={i}
            reff={ref}
            imgs={imgs}
            current={current}
            setCurrent={setCurrent}
          />
        ))}
      </motion.div>
    </>
  )
}
